import request from '../request'

/**
 * Get database information on the current user
**/

async function adminPushMembers() {
    return request('/admin/join4join/auth')
}

export default adminPushMembers