import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import Seo from "../../utils/Seo";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../utils/loading";
import getUserData from "../../api/methods/getUserData";
import getGuildApiToken from "../../api/methods/getGuildApiToken";
import claimGuildApiToken from "../../api/methods/claimGuildApiToken";
import deleteGuildApiToken from "../../api/methods/deleteGuildApiToken";

const PurchaseAPI = () => {
    const [load, setLoad] = useState(undefined);
    const [tokenData, setTokenData] = useState(undefined);
    const [status, setStatus] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const location = useLocation();

    const fetchTransactionData = async () => {
        setLoad(true);
        const token = window.location.pathname.split("/")[2];
        const checkToken = await getGuildApiToken(token);
        if (checkToken && checkToken.success === true) {
            setTokenData(checkToken.data);
            setLoad(false);
        } else {
            window.location.replace("/");
        }
    };

    const confirmTransaction = async () => {
        setLoad(true);
        const claimToken = await claimGuildApiToken(window.location.pathname.split("/")[2]);
        console.log(claimToken)
        setLoad(false);
        if (claimToken && claimToken.success === true) {
            setStatus({
                type: "success",
                text: "Advertising confirmed successfully!",
            });
        } else {
            setStatus({
                type: "error",
                text: "An error occurred while confirming the advertising.",
            });
        }
    };

    const cancelTransaction = async () => {
        setLoad(true);
        const deleteToken = await deleteGuildApiToken(window.location.pathname.split("/")[2]);
        setLoad(false);
        if (deleteToken && deleteToken.success === true) {
            setStatus({
                type: "success",
                text: "Advertising cancelled successfully!",
            });
        } else {
            setStatus({
                type: "error",
                text: "An error occurred while cancelling the advertising.",
            });
        }
    };

    useEffect(async () => {
        const request = await getUserData();
        if (request && request.success === true) {
            setUser(request.data);
            fetchTransactionData();
            const script = document.createElement("script")
            script.src = "https://www.google.com/recaptcha/api.js?render=6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E"
            document.body.appendChild(script)
        } else {
            window.localStorage.setItem("callback", location.pathname);
            window.location.replace("/login/discord");
        }
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            {user ? <div className="api-container">
                <div className="api-content">
                    {load ? (
                        <>
                            <Loading />
                            <br />
                            <h1>Processing the advertising...</h1>
                            <p>Please keep this window open during the process.</p>
                        </>
                    ) : status ? (
                        <>
                            {
                                status.type === "success" && <FaCheckCircle className="api-icon" />
                            }
                            {
                                status.type === "error" && <FaTimesCircle className="api-icon error" />
                            }
                            <h1>{status.text}</h1>
                            <p>You may close this window.</p>
                            <button onClick={() => { window.location.replace("/") }}>Home</button>
                        </>
                    ) : (
                        <>
                            <FaCheckCircle className="api-icon" />
                            <h1>Advertising Details</h1>
                            <br />
                            <span>Amount: {tokenData?.coins} coins</span>
                            <span>Filter alts: {tokenData.filter_alts && tokenData.filter_alts === 1 ? "on" : "off"}</span>
                            <span>Filter language: {!["en", "fr", "tr"].includes(tokenData.filter_lang) ? "all" : tokenData.filter_lang}</span>
                            <span>Mode: invite</span>
                            <div className="api-buttons">
                                <button onClick={confirmTransaction}>Confirm</button>
                                <button className="btn-red" onClick={cancelTransaction}>Cancel</button>
                            </div>
                        </>
                    )}
                </div>
            </div> :
                <>
                    <div class="dots-container">
                        <div class="dots"></div>
                        <div class="dots"></div>
                        <div class="dots"></div>
                        <div class="dots"></div>
                        <div class="dots"></div>
                    </div>
                </>
            }
        </>
    );
};

export default PurchaseAPI;
