import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../public/img/logoj4j.png";
import { useEffect } from "react";
import login from "../../../api/methods/login";
import getUserData from "../../../api/methods/getUserData";
import { useState } from "react";
import initiale from "../../../utils/initiale";
import { SUPPORT_SERVER } from "../../../App";

const Nav = () => {
    const [user, setUser] = useState(undefined);

    const profileDropDown = () => {
        const profile = document.querySelector('nav .profile');
        const dropdownProfile = profile.querySelector('.profile-link');
        dropdownProfile.classList.toggle('show');
    }

    const location = useLocation();

    const Menu = () => {
        const profile = document.querySelector('nav .profile');
        const dropdownProfile = profile.querySelector('.profile-link');
        const allDropdown = document.querySelectorAll('#sidebar .side-dropdown');
        const allSideDivider = document.querySelectorAll('#sidebar .divider');
        const nav = document.querySelector("nav");

        const sidebar = document.getElementById('sidebar');
        sidebar.classList.toggle('hide');
        nav.classList.toggle('hide');

        if (sidebar.classList.contains('hide')) {
            allSideDivider.forEach(item => {
                item.textContent = '-'
            })
            allDropdown.forEach(item => {
                const a = item.parentElement.querySelector('a:first-child');
                a.classList.remove('active');
                item.classList.remove('show');
            })
        } else {
            allSideDivider.forEach(item => {
                item.textContent = item.dataset.text;
            })
        }

        if (dropdownProfile.classList.contains('show')) {
            dropdownProfile.classList.remove('show');
        }

        const allLinks = document.querySelectorAll('.side-menu a');

        allLinks.forEach((link) => {
            link.classList.remove('active');
        });

        const allDropdowns = document.querySelectorAll('.side-dropdown');

        allDropdowns.forEach((dropdown) => {
            dropdown.classList.remove('show');
        });
    }

    const logout = () => {
        window.location.replace("/");
        window.localStorage.removeItem("token")
    }

    const getUserdata_ = async () => {
        const request = await getUserData();
        if (request && request.success === true) {
            setUser(request.data);
        } else {
            window.localStorage.setItem("callback", location.pathname);
            window.location.replace("/login");
        }
    }

    useEffect(() => {
        if (location.pathname.includes('/dashboard')) {
            getUserdata_()
        }
    }, []);

    return (
        <>
            <nav>
                <i className='bx bx-menu toggle-sidebar' onClick={Menu}></i>
                <a href={SUPPORT_SERVER} target="_blank" rel="noopener noreferrer" className="support-link">
                    <i className='bx bxl-discord'></i><span className="text-support-link">Support</span>
                </a>
                <Link to={"/dashboard/premium"} className="nav-link">
                    <button className="btn-premium">Premium</button>
                </Link>
                <Link to={"/dashboard/join4join/history"} className="nav-link">
                    <a className="display_coins" id="coins">{user ? user.money : "0"}</a>
                </Link>
                <div className="profile" onClick={() => { profileDropDown() }}>
                    <img src={user && user.avatar && user.avatar != "0" ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}` : `https://dummyimage.com/400x400/000/ffffff&text=${user ? initiale(user.username) : ""}`} alt="Navbar user pp" />
                    <ul className="profile-link">
                        <li><Link to={"/dashboard/settings"}><i className='bx bxs-cog'></i>Settings</Link></li>
                        {
                            user && user.permissions === 1 && (
                                <li><Link to={"/dashboard/admin"}><i className='bx bxs-shield-minus'></i>Admin</Link></li>
                            )
                        }
                        <li><a href="/" onClick={() => { logout() }}><i className='bx bxs-log-out-circle'></i>Logout</a></li>

                    </ul>
                </div>
            </nav>
        </>
    );
};

export default Nav;