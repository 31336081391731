import Seo from "../../../utils/Seo";
import Check_Page from "../components/check";

const Check = () => {
    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <Check_Page />
            </section>
        </>
    );
};

export default Check;