import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from "react-router-dom";
import Seo from "../../utils/Seo";
import { useEffect } from "react";
import { useState } from "react";
import leaderboardCoin from "../../api/methods/leaderboardCoin";
import Loading from "../../utils/loading";
import initiale from "../../utils/initiale";
import leaderboardAffiliation from "../../api/methods/leaderboardAffiliation";

const MySwal = withReactContent(Swal)



const Leaderboard = () => {
    const [load, setLoad] = useState(undefined);
    const [leaderboard, setLeaderboard] = useState([]);
    const [statsName, setStatsName] = useState("coin");

    const getLeaderboardCoin = async () => {
        const request = await leaderboardCoin();
        if (request && request.success === true) {
            setLeaderboard(request.data);
            setLoad(false)
        }
    }

    const getLeaderboardAffiliation = async () => {
        const request = await leaderboardAffiliation();
        if (request && request.success === true) {
            setLeaderboard(request.data);
            setLoad(false)
        }
    }

    const editLeaderboard = async (element) => {
        if (element && element.target && element.target.value) {
            if (element.target.value === "coin") {
                setLoad(true)
                getLeaderboardCoin()
                setStatsName("coin")
            } else if (element.target.value === "affiliation") {
                setLoad(true)
                getLeaderboardAffiliation()
                setStatsName("affiliation")
            }
        }
    }

    useEffect(() => {
        setLoad(true)
        getLeaderboardCoin()
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <main>
                    <h1 className="title">Leaderboard</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard"}>Home</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Leaderboard</a></li>
                    </ul>
                    <div className="data">
                        <div className="content-data">
                            <div className="head.all">
                                <h3 className="title">Leaderboard Page</h3>
                            </div>
                            <div className="select_leaderboard" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <select onChange={editLeaderboard.bind(this)}>
                                    <option value="coin">Coin leaderboard (always)</option>
                                    <option value="affiliation">Affiliation leaderboard (this month)</option>
                                </select>
                            </div>
                            {load ?
                                <>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Loading />
                                    </div>
                                </>
                                :
                                <>
                                    <div className="leaderboard col-xl-4 col-10">
                                        <div className="leaderboard-content">
                                            <div className="leaderboard-triple">
                                                <div className="second all-triple">
                                                    <div className="all-triple-name">
                                                        <p>{leaderboard && leaderboard[1]?.name ? leaderboard[1].name : undefined}</p>
                                                    </div>
                                                    <img src={leaderboard && leaderboard[1]?.avatar && leaderboard[1]?.avatar != undefined ? `https://cdn.discordapp.com/avatars/${leaderboard[1]?.id}/${leaderboard[1]?.avatar}` : `https://dummyimage.com/400x400/000/ffffff&text=${leaderboard[1] ? initiale(leaderboard[1]?.name) : ""}`} />
                                                    <p>{leaderboard && leaderboard[1]?.stats ? leaderboard[1].stats : undefined}</p>
                                                </div>
                                                <div className="first all-triple">
                                                    <div className="all-triple-name">
                                                        <p>{leaderboard && leaderboard[0]?.name ? leaderboard[0].name : undefined}</p>
                                                    </div>
                                                    <img src={leaderboard && leaderboard[0]?.avatar && leaderboard[0]?.avatar != undefined ? `https://cdn.discordapp.com/avatars/${leaderboard[0]?.id}/${leaderboard[0]?.avatar}` : `https://dummyimage.com/400x400/000/ffffff&text=${leaderboard[0] ? initiale(leaderboard[0]?.name) : ""}`} />
                                                    <p>{leaderboard && leaderboard[0]?.stats ? leaderboard[0].stats : undefined}</p>
                                                </div>
                                                <div className="third all-triple">
                                                    <div className="all-triple-name">
                                                        <p>{leaderboard && leaderboard[2]?.name ? leaderboard[2].name : undefined}</p>
                                                    </div>
                                                    <img src={leaderboard && leaderboard[2]?.avatar && leaderboard[2]?.avatar != undefined ? `https://cdn.discordapp.com/avatars/${leaderboard[2]?.id}/${leaderboard[2]?.avatar}` : `https://dummyimage.com/400x400/000/ffffff&text=${leaderboard[2] ? initiale(leaderboard[2]?.name) : ""}`} />
                                                    <p>{leaderboard && leaderboard[2]?.stats ? leaderboard[2].stats : undefined}</p>
                                                </div>
                                            </div>
                                            <div className="leaderboard-body titletable">
                                                <div className="leaderboard-user-count-title user-title">
                                                    <p>Count</p>
                                                </div>
                                                <div className="leaderboard-user-name-title user-title">
                                                    <p>Username</p>
                                                </div>
                                                <div className="leaderboard-user-point-title user-title">
                                                    <p>{statsName ? statsName : "stats"}</p>
                                                </div>
                                            </div>
                                            {
                                                leaderboard.length > 0 ? leaderboard.slice(3).map((leaderboards) => {
                                                    if (leaderboards && leaderboards.user && leaderboards.user === "yeah") {
                                                        return (
                                                            <>
                                                                <div className="leaderboard-body" style={{ backgroundColor: "pink" }}>
                                                                    <div className="leaderboard-user-count user-info">
                                                                        <p>#{leaderboards.position}</p>
                                                                    </div>
                                                                    <div className="leaderboard-user-name user-info">
                                                                        <p>{leaderboards.name}</p>
                                                                    </div>
                                                                    <div className="leaderboard-user-point user-info">
                                                                        <p>{leaderboards.stats}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <>
                                                                <div className="leaderboard-body">
                                                                    <div className="leaderboard-user-count user-info">
                                                                        <p>#{leaderboards.position}</p>
                                                                    </div>
                                                                    <div className="leaderboard-user-name user-info">
                                                                        <p>{leaderboards.name}</p>
                                                                    </div>
                                                                    <div className="leaderboard-user-point user-info">
                                                                        <p>{leaderboards.stats}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                }) : undefined
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default Leaderboard;