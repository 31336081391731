import { useEffect } from "react";
import Seo from "../../utils/Seo";
import getRandomBot from "../../api/methods/getRandomBot";

const Bot = () => {
    useEffect(() => {
        setTimeout(async () => {
            const request = await getRandomBot();
            if (request && request.success === true) {
                if (request?.data?.id) {
                    window.location.replace("https://discord.com/oauth2/authorize?client_id=" + request.data.id + "&permissions=application.commands&scope=bot")
                }
            }

        }, 2000);
    }, []);

    return (
        <>
            <Seo dynamic={true}
                title={"Join4Join Discord Bot"}
                description={"Add now the official Discord Bot for join4join"}
            />
            <div class="dots-container">
                <div class="dots"></div>
                <div class="dots"></div>
                <div class="dots"></div>
                <div class="dots"></div>
                <div class="dots"></div>
            </div>
        </>
    );
};

export default Bot;