import Seo from "../../../utils/Seo";
import Manage_ID_Page from "../components/manage_id";


const Manage_ID = () => {
    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <Manage_ID_Page />
            </section>
        </>
    );
};

export default Manage_ID;