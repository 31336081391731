import Seo from "../../../utils/Seo";
import History_Page from "../components/history";

const History = () => {
    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <History_Page />
            </section>
        </>
    );
};

export default History;