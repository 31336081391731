import { useEffect, useState } from "react";
import createPayement from "../../api/methods/createPayement";
import ReactGA from 'react-ga';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loading from "../../utils/loading";
import { Link } from "react-router-dom";
import Seo from "../../utils/Seo";
import { SUPPORT_SERVER } from "../../App";

const MySwal = withReactContent(Swal)

const Premium = () => {
    const [coin, setCoin] = useState(0);
    const [load, setLoad] = useState(undefined);

    const stripeSimulation = () => {
        const premium = document.getElementById("stripesimulation");
        if (premium && premium.value) {
            if (typeof (Number(premium.value)) === "number" && Number(premium.value) !== 0 && Number(premium.value) >= 50 && Number(premium.value) <= 8000) {
                document.getElementById("stripesimulationresult").innerHTML = 'You will pay ' + parseInt(Number(premium.value) / 50).toFixed(2) + '$ to receive ' + parseInt(Number(premium.value) / 50) * 50 + ' coins'
                setCoin(parseInt(Number(premium.value) / 50) * 50);
            } else {
                document.getElementById("stripesimulationresult").innerHTML = 'You need to provide a number between 50 and 8000 coins.'
                setCoin(0);
            }
        }
    }

    const buy = async (coinAmount) => {
        if (!coinAmount) {
            MySwal.fire({
                title: 'Params Missing',
                html: <>
                    <p>Please provide an amount of coins.</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "error"
            })
        } else if (coinAmount) {
            setLoad(true);
            const link = await createPayement(coinAmount);
            if (link && link.success === true) {
                window.location.replace(link.data.url)
                setLoad(false);
            }
        }
    }

    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <main>
                    <h1 className="title">Premium</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard"}>Home</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Premium</a></li>
                    </ul>
                    <div className="info-data">
                        <div className="card">
                            <div className="head">
                                <div>
                                    <h2>+ 50 000</h2>
                                    <p>Customers</p>
                                </div>
                                <i className='bx bx-trending-up icon' ></i>
                            </div>
                        </div>
                        <div className="card">
                            <div className="head">
                                <div>
                                    <h2>+ 1000</h2>
                                    <p>Daily Users</p>
                                </div>
                                <i className='bx bx-trending-up icon'></i>
                            </div>
                        </div>
                        <div className="card">
                            <div className="head">
                                <div>
                                    <h2>100%</h2>
                                    <p>Instant delivery</p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="head">
                                <div>
                                    <h2>24/7</h2>
                                    <p>Support</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="data">
                        <div className="content-data">
                            <div className="head.all">
                                <h3 className="title">Purchase Coins</h3>
                            </div>
                            <div className="db-container background">
                                {load ? <Loading /> :
                                    <>
                                        <div className="packs">
                                            <h3>Coins Packs</h3>
                                            <p className="packs-description">Choose from our available coin packs to quickly boost your server's visibility. Each coin equals one new member!</p>
                                            <div className="packs-container">
                                                <div className="packs-item">
                                                    <h4>Pack 250 Coins</h4>
                                                    <p>Great for starting a campaign.</p>
                                                    <p><b>250 Members</b> | <b>5$</b></p>
                                                    <button onClick={() => { buy(250); }}>Buy Now</button>
                                                </div>
                                                <div className="packs-item">
                                                    <h4>Pack 500 Coins</h4>
                                                    <p>Perfect for mid-sized server growth.</p>
                                                    <p><b>500 Members</b> | <b>10$</b></p>
                                                    <button onClick={() => { buy(500); }}>Buy Now</button>
                                                </div>
                                                <div className="packs-item">
                                                    <h4>Pack 750 Coins</h4>
                                                    <p>The ultimate pack for large boosts.</p>
                                                    <p><b>750 Members</b> | <b>15$</b></p>
                                                    <button onClick={() => { buy(750); }}>Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form">
                                            <h3>Amount of desired coins (1 join costs 1 coin)</h3>
                                            <p id="stripesimulationresult" style={{ color: "rgb(18 175 87)" }}></p>
                                            <div className="send_premium_form">
                                                <input onInput={stripeSimulation} type="text" id="stripesimulation" placeholder="Example: 500" />
                                            </div>
                                            <button className="btn-premium" onClick={() => { buy(coin); }}>Purchase</button>
                                            <br />
                                            <span className="premium_s">Payement powered and secured by Stripe</span>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </section >
        </>
    );
};

export default Premium;