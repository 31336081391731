import { Link, useNavigate } from "react-router-dom";
import getUserCheck from "../../../api/methods/getUserCheck";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../../utils/loading";

const Check_Page = () => {
    const navigate = useNavigate();

    const [check_server, setCheck_server] = useState([]);
    const [load, setLoad] = useState(undefined);

    const getCheckServers = async () => {
        const request = await getUserCheck();
        if (request && request.success === true) {
            setCheck_server(request.data);
            setLoad(undefined);
        } else if (request && request.success === false) {
            window.localStorage.removeItem("token")
            window.location.replace("/");
        }
    }

    useEffect(() => {
        getCheckServers();
        setLoad(true);
    }, []);


    return (
        <>
            <main>
                <h1 className="title">Check</h1>
                <ul className="breadcrumbs">
                    <li><Link to={"/dashboard/join4join"}>Join4Join</Link></li>
                    <li className="divider">/</li>
                    <li><a href="#" className="active">Check</a></li>
                </ul>
                <div className="data">
                    <div className="content-data">
                        <div className="head.all">
                            <h3 className="title">Check page</h3>
                        </div>
                        <div className="db-container background">
                            {
                                load ? <Loading /> :
                                    <>
                                        <div className="display_check">
                                            {
                                                check_server.length > 0 ? check_server.map((guild) => {
                                                    return (
                                                        <>
                                                            <img src={guild.icon && guild.icon != "0" && guild.icon != "null" ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}` : `https://dummyimage.com/400x400/000/ffffff&text=${guild.name}`} alt={guild.name} title={guild.name} />
                                                        </>
                                                    )
                                                }) : <>
                                                    <div className="check_flex">
                                                        <p>You don't have servers to leave at the moment.</p>
                                                        <button onClick={() => { navigate("/dashboard/join4join/farm") }} className="button button1">Join servers</button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Check_Page;