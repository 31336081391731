import { Link, useLocation } from "react-router-dom";
import Logo from "../../../public/img/logoj4j.png";
import { useEffect, useState } from "react";
import getRandomBot from "../../../api/methods/getRandomBot";
import { SUPPORT_SERVER } from "../../../App";

const Sidebar = () => {
    const [botID, setBotID] = useState(undefined);
    const location = useLocation();

    const getRandomBot_ = async () => {
        const request = await getRandomBot();
        if (request && request.success === true) {
            if (request?.data?.id) {
                setBotID(request.data.id);
            }
        }
    };

    useEffect(() => {
        if (window.innerWidth < 500) {
            const sidebar = document.getElementById("sidebar");
            const nav = document.querySelector("nav");
            sidebar.classList.add("hide");
            nav.classList.add("hide");
            const allSideDivider = document.querySelectorAll('#sidebar .divider');
            const allDropdown = document.querySelectorAll('#sidebar .side-dropdown');

            if (sidebar.classList.contains('hide')) {
                allSideDivider.forEach(item => {
                    item.textContent = '-'
                })
                allDropdown.forEach(item => {
                    const a = item.parentElement.querySelector('a:first-child');
                    a.classList.remove('active');
                    item.classList.remove('show');
                })
            } else {
                allSideDivider.forEach(item => {
                    item.textContent = item.dataset.text;
                })
            }
        }

        getRandomBot_();
    }, []);

    const isActive = (path) => location.pathname === path;

    return (
        <section id="sidebar">
            <Link to="/" className="brand">
                <img src={Logo} alt="Join4Join Logo" /> Join4Join
            </Link>
            <ul className="side-menu">
                <li className="divider" data-text="join4join">Join4Join</li>
                <li>
                    <Link
                        to="/dashboard"
                        className={isActive("/dashboard") ? "active" : undefined}
                    >
                        <i className="fa fa-store icon"></i>Manage
                    </Link>
                </li>
                <li>
                    <Link
                        to="/dashboard/join4join/farm"
                        className={isActive("/dashboard/join4join/farm") ? "active" : undefined}
                    >
                        <i className="fa fa-rocket icon"></i>Farm
                    </Link>
                </li>
                <li>
                    <Link
                        to="/dashboard/join4join/check"
                        className={isActive("/dashboard/join4join/check") ? "active" : undefined}
                    >
                        <i className="fa-solid fa-circle-check icon"></i>Check
                    </Link>
                </li>
                <li>
                    <Link
                        to="/dashboard/premium"
                        className={isActive("/dashboard/premium") ? "active" : undefined}
                        style={{ color: "white", backgroundColor: "#bd17f1" }}
                    >
                        <i className="fa-solid fa-cart-arrow-down icon"></i>Premium
                    </Link>
                </li>
                <li className="divider" data-text="more">More</li>
                <li>
                    <Link
                        to="/dashboard/transfer"
                        className={isActive("/dashboard/transfer") ? "active" : undefined}
                    >
                        <i className="fa fa-arrow-right-arrow-left icon"></i>Transfer
                    </Link>
                </li>
                <li>
                    <Link
                        to="/dashboard/rewards"
                        className={isActive("/dashboard/rewards") ? "active" : undefined}
                    >
                        <i className="bx bxs-gift icon"></i>Rewards
                    </Link>
                </li>
                <li>
                    <a
                        href={
                            botID
                                ? `https://discord.com/api/oauth2/authorize?client_id=${botID}&permissions=application.commands&scope=bot`
                                : undefined
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="bx bxs-plus-circle icon"></i>Add A Join4Join Bot
                    </a>
                </li>
                <li>
                    <a
                        href={SUPPORT_SERVER}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            color: "white",
                            backgroundColor: '#17f166'
                        }}

                        className="sidebar-support-link"
                    >
                        <i className="bx bxl-discord icon"></i>Support Server
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href="/documentation">
                        <i className="fa fa-code icon"></i>Api Documentation
                    </a>
                </li>
                <li className="divider" data-text="stats">Stats</li>
                <li>
                    <Link
                        to="/dashboard/affiliation"
                        className={isActive("/dashboard/affiliation") ? "active" : undefined}
                    >
                        <i className="bx bx-line-chart icon"></i>Affiliation
                    </Link>
                </li>
                <li>
                    <Link
                        to="/dashboard/leaderboard"
                        className={isActive("/dashboard/leaderboard") ? "active" : undefined}
                    >
                        <i className="bx bxs-pie-chart-alt-2 icon"></i>Leaderboard
                    </Link>
                </li>
            </ul>
        </section >
    );
};

export default Sidebar;
