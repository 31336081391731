import { useState } from "react";
import sendCoins from "../../api/methods/sendCoins";
import coinsEffect from "../../utils/coinsEffect";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Seo from "../../utils/Seo";
import { SUPPORT_SERVER } from "../../App";

const MySwal = withReactContent(Swal)

const Transfer = () => {
    const [friend_id, setFriendID] = useState(undefined);
    const [coins, setCoins] = useState(undefined);

    const transfer_coins = async () => {
        if (coins && friend_id) {
            const checkSendCoins = await sendCoins(friend_id, coins)
            if (checkSendCoins.success === true) {
                MySwal.fire({
                    title: 'Success',
                    html: <>
                        <p>Your friend have received {coins} coins</p>
                    </>,
                    footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                    timer: 3000,
                    icon: "success"
                })
                coinsEffect();
            } else {
                MySwal.fire({
                    title: 'Error',
                    html: <>
                        <p>{checkSendCoins.error}</p>
                    </>,
                    footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                    timer: 3000,
                    icon: "error"
                })
            }
        } else {
            MySwal.fire({
                title: 'Params Missing',
                html: <>
                    <p>You need to provide your friend ID and the amount of coins you want to give. Try again...</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "error"
            })
        }
    }

    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E"
        document.body.appendChild(script)
    }, []);


    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <main>
                    <h1 className="title">Transfer</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard"}>Home</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Transfer</a></li>
                    </ul>
                    <div className="data">
                        <div className="content-data">
                            <div className="head.all">
                                <h3 className="title">Transfer Coins Page</h3>
                            </div>
                            <div className="db-container background">
                                <div className="form">
                                    <h3>Amount of coins to send:</h3>
                                    <div className="send_premium_form">
                                        <input onChange={(event) => setCoins(event.target.value)} type="text" placeholder="Example: 10" />
                                    </div>
                                    <h3>Discord ID of recipient</h3>
                                    <div className="send_premium_form">
                                        <input onChange={(event) => setFriendID(event.target.value)} type="text" placeholder="Example: 1110640127979565086" />
                                    </div>
                                    <button onClick={() => { transfer_coins() }}>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default Transfer;