import Seo from "../../../utils/Seo";
import Token_Page from "../components/token";


const Token = () => {
    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <Token_Page />
            </section>
        </>
    );
};

export default Token;