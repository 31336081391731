import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
import reactGa from "react-ga";
import Index from './website/landing_page';
import About from './website/landing_page/about';
import Features from './website/landing_page/features';
import Faq_page from './website/landing_page/faq';
import Terms from './website/landing_page/terms';
import Privacy from './website/landing_page/privacy';
import Api from './website/api/documentation';
import Join4Join from './website/dashboard/join4join';
import Manage from './website/dashboard/join4join/manage';
import Farm from './website/dashboard/join4join/farm';
import Check from './website/dashboard/join4join/check';
import Premium from './website/dashboard/premium';
import NotFound from './website/landing_page/404';
import History from './website/dashboard/join4join/history';
import Settings from './website/dashboard/settings';
import Rewards from './website/dashboard/rewards';
import Transfer from './website/dashboard/transfer';
import Manage_ID from './website/dashboard/join4join/manage_id';
import Token from './website/dashboard/join4join/token';
import Admin from './website/dashboard/admin';
import Manage_Bot from './website/dashboard/developer/manage';
import Add_Bot from './website/dashboard/developer/add';
import Developer from './website/dashboard/developer';
import Nav_dashboard from './website/dashboard/layout/nav';
import Sidebar_dashboard from './website/dashboard/layout/sidebar';
import Nav_landing_page from './website/landing_page/layout/nav';
import Leaderboard from './website/dashboard/leaderboard';
import Affiliation from './website/dashboard/affiliation';
import Login from './website/landing_page/login';
import Support from './website/landing_page/support';
import Bot from './website/landing_page/bot';
import TransferAPI from './website/api/transfer';
import ClaimAPI from './website/api/claim';
import PurchaseAPI from './website/api/purchase';

reactGa.initialize("G-TW2S8CLHZQ")
reactGa.pageview(window.location.pathname);

export const SUPPORT_SERVER = "https://discord.gg/u22gwJssm5"

function Navigation() {
  const location = useLocation();

  // if (location.pathname.includes('code')) {
  //   window.document.querySelector("body").classList.add("login-page")
  //   return <>
  //     <div class="dots-container">
  //       <div class="dots"></div>
  //       <div class="dots"></div>
  //       <div class="dots"></div>
  //       <div class="dots"></div>
  //       <div class="dots"></div>
  //     </div>
  //   </>
  // }

  if (location.pathname.includes('/dashboard')) {

    return (
      <>
        <Nav_dashboard />
        <Sidebar_dashboard />
      </>
    )
  }

  if (location.pathname === "/"
    || location.pathname.includes('/about')
    || location.pathname.includes('/features')
    || location.pathname.includes('/faq')
    || location.pathname.includes('/terms')
    || location.pathname.includes('/privacy')
    || location.pathname.includes('/login')) {

    return <Nav_landing_page />
  }

  if (["/support",
    "/support/",
    "/bot", "/bot/",
    "/callback", "/callback/",
    "/documentation", "/documentation/",
    "/claim", "/claim/",
    "/transfer", "/transfer/",
    "/purchase", "/purchase/"].includes(location.pathname) || location.pathname.startsWith("/transfer/") || location.pathname.startsWith("/purchase/")) return;
  return <Nav_landing_page />
}

function App() {
  const [notFound, setNotFound] = useState(undefined);

  useEffect(() => {
    const notFoundElement = document.querySelector('.notfound');
    if (notFoundElement) {
      setNotFound(true);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://adsreference.com/widget.js?id=1705618166572112000"
    document.body.appendChild(script)
  }, []);

  return (
    <div className="root">
      <div className="g-recaptcha" data-sitekey="6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E" data-size="invisible"></div>

      <BrowserRouter>
        {notFound ? <Nav_landing_page /> : <Navigation />}
        <Routes>
          /** LANDING PAGE */
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/features" element={<Features />} />
          <Route path="/faq" element={<Faq_page />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/login" element={<Login />} />

          /** DASHBOARD PAGE */
          /** GLOBAL - PART */
          <Route path="/dashboard" element={<Manage />} />
          <Route path="/dashboard/premium" element={<Premium />} />
          <Route path="/dashboard/settings" element={<Settings />} />
          <Route path="/dashboard/rewards" element={<Rewards />} />
          <Route path="/dashboard/transfer" element={<Transfer />} />
          <Route path="/dashboard/leaderboard" element={<Leaderboard />} />
          <Route path="/dashboard/affiliation" element={<Affiliation />} />
          <Route path="/dashboard/admin" element={<Admin />} />

          /** DEVELOPER - PART */
          <Route path="/dashboard/bot/manage" element={<Manage_Bot />} />
          <Route path="/dashboard/bot/add" element={<Add_Bot />} />
          <Route path="/dashboard/bot" element={<Developer />} />

          /** REDIRECTION - PART */
          <Route path="/support" element={<Support />} />
          <Route path="/bot" element={<Bot />} />

          /** JOIN4JOIN - PART */
          <Route path="/dashboard/join4join" element={<Join4Join />} />
          <Route path="/dashboard/join4join/manage/:id" element={<Manage_ID />} />
          <Route path="/dashboard/join4join/farm" element={<Farm />} />
          <Route path="/dashboard/join4join/check" element={<Check />} />
          <Route path="/dashboard/join4join/history" element={<History />} />
          <Route path="/dashboard/join4join/token/:action/:token" element={<Token />} />

          /** API DOCUMENTATION & MORE */
          <Route path="/documentation" element={<Api />} />
          <Route path="/claim" element={<ClaimAPI />} />
          <Route path="/transfer/:token" element={<TransferAPI />} />
          <Route path="/purchase/:token" element={<PurchaseAPI />} />

          /** ROUTE PAGE */
          <Route
            path="*"
            element={
              window.location.pathname !== "/login" && window.location.pathname !== "/callback" ? (
                <NotFound />
              ) : null
            }
          />
          <Route
            path="/close"
            element={
              <>
                {() => {
                  window.close();
                }}
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;