import { FaCheckCircle } from "react-icons/fa";
import refreshRewards from "../../api/methods/refreshRewards";
import Seo from "../../utils/Seo";
import { useEffect, useState } from "react";
import getUserData from "../../api/methods/getUserData";
import { useLocation } from "react-router-dom";
import Loading from "../../utils/loading";

const ClaimAPI = () => {
    const [load, setLoad] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const location = useLocation();

    const refreshRewards_ = async () => {
        setLoad(true);
        const request = await refreshRewards();
        if (request && request.success === true) {
            setLoad(false);
        }
    }

    useEffect(async () => {
        const request = await getUserData();
        if (request && request.success === true) {
            setUser(request.data);
            refreshRewards_();
        } else {
            window.localStorage.setItem("callback", location.pathname);
            window.location.replace("/login/discord");
        }
    }, [])

    return (
        <>
            <Seo dynamic={false} />
            {user ? <div className="api-container">
                <div className="api-content">
                    {
                        load ? (
                            <>
                                <Loading />
                                <br />
                                <h1>Updating your balance...</h1>
                                <p>Please keep this window open during the process.</p>
                            </>
                        ) : (
                            <>
                                <FaCheckCircle className="api-icon" />
                                <h1>Balance Successfully updated!</h1>
                                <p>You may now close this window.</p>
                                <button onClick={() => { window.location.replace("/") }}>Home</button>
                            </>
                        )
                    }
                </div>
            </div> :
                <>
                    <div class="dots-container">
                        <div class="dots"></div>
                        <div class="dots"></div>
                        <div class="dots"></div>
                        <div class="dots"></div>
                        <div class="dots"></div>
                    </div>
                </>
            }
        </>
    );
};

export default ClaimAPI;