import { Link } from "react-router-dom";
import Seo from "../../../utils/Seo";
import getBotList from "../../../api/methods/getBotList";
import { useEffect, useState } from "react";
import Loading from "../../../utils/loading";
import deleteBot from "../../../api/methods/deleteBot";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import editBotToken from "../../../api/methods/editBotToken";
import getBotDaily from "../../../api/methods/getBotDaily";
import coinsEffect from "../../../utils/coinsEffect";
import parsems from "parse-ms"
import editPublicBot from "../../../api/methods/editPublicBot";

const MySwal = withReactContent(Swal)

const Manage_Bot = () => {
    const [bots, setBots] = useState([]);
    const [load, setLoad] = useState(undefined);

    const getUserBots = async () => {
        const request = await getBotList();
        if (request && request.success === true) {
            setBots(request.data);
            setLoad(undefined)
        } else {
            window.localStorage.removeItem("token");
            window.location.replace("/");
        }
    }

    const deleteBot_ = async (id) => {
        const request = await deleteBot(id);
        if (request && request.success === true) {
            const request = await getBotList();
            if (request && request.success === true) {
                setBots(request.data);
                setLoad(undefined)
            }
        }
    }

    const switchMode = async (id) => {
        const request = await editPublicBot(id);
        console.log(request)
        if (request && request.success === true) {
            const request = await getBotList();
            if (request && request.success === true) {
                setBots(request.data);
                setLoad(undefined)
            }
        }
    }


    const botDaily = async (id) => {
        const request = await getBotDaily(id);
        if (request && request.success === true) {
            coinsEffect();
            MySwal.fire({
                title: 'Daily Bot Rewards',
                html: <>
                    <p>You claimed your {request.coins} coins daily bot rewards!</p>
                    <br />
                    <p>Come back in 24 hours</p>
                </>,
                footer: '<a href="https://t.me/join4joindiscord">Support Server</a>',
                timer: 3000,
                icon: "success"
            })
        } else {
            if (request.data && request.data.id) {
                const time_left = parsems(86400000 - (Date.now() - request.data.daily));
                MySwal.fire({
                    title: 'Daily Bot Rewards',
                    html: <>
                        <p>You already claimed your daily bot rewards!</p>
                        <br />
                        <p>Come back in <strong>{time_left.hours}h {time_left.minutes}m {time_left.seconds}s</strong></p>
                    </>,
                    footer: '<a href="https://t.me/join4joindiscord">Support Server</a>',
                    timer: 3000,
                    icon: "error"
                })
            } else {
                MySwal.fire({
                    title: 'Daily Bot Rewards',
                    html: <>
                        <p>An error appear. Please try again</p>
                    </>,
                    footer: '<a href="https://t.me/join4joindiscord">Support Server</a>',
                    timer: 3000,
                    icon: "error"
                })
            }
        }
    }



    const editToken = async (id) => {
        const { value: formValues } = await MySwal.fire({
            title: 'Change the bot token',
            html:
                `<input id="swal-input1" class="swal2-input" type="text" placeholder="New Bot Token">`,
            footer: '<a href="https://discord.gg/join4join">Support Server</a>',
            focusConfirm: false,
            icon: "question",
            showCancelButton: true,
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value,
                ]
            }
        })

        if (formValues) {
            const token = formValues[0]

            if (!token) {
                MySwal.fire({
                    title: 'Params Missing',
                    html: <>
                        <p>You need to provide a new token. Try again...</p>
                    </>,
                    footer: '<a href="https://discord.gg/join4join">Support Server</a>',
                    timer: 3000,
                    icon: "error"
                })
            } else {
                const request = await editBotToken(id, token)
                console.log(request);
                if (request.success === true) {
                    MySwal.fire({
                        title: 'Successfully',
                        html: <>
                            <p>Your changed the token of the bot.</p>
                        </>,
                        footer: '<a href="https://discord.gg/join4join">Support Server</a>',
                        timer: 3000,
                        icon: "success"
                    })
                } else {
                    MySwal.fire({
                        title: 'Error',
                        html: <>
                            <p>{request.message}</p>
                        </>,
                        footer: '<a href="https://discord.gg/join4join">Support Server</a>',
                        timer: 3000,
                        icon: "error"
                    })
                }
            }
        }
    }

    useEffect(() => {
        setLoad(true)
        getUserBots()
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <main>
                    <h1 className="title">Your Bots</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard/bot"}>Bot</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Your Bots</a></li>
                    </ul>
                    <div className="data">
                        <div className="content-data">
                            <div className="head.all">
                                <h3 className="title">Your Bots</h3>
                                <div className="db-container background">
                                    {load ? <Loading /> :
                                        <>
                                            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                                                {
                                                    bots.length > 0 ? bots.map((bot) => {
                                                        return (
                                                            <>
                                                                <div className="display_guild_list">
                                                                    <a href={`https://discord.com/api/oauth2/authorize?client_id=${bot?.id}&permissions=8&scope=bot`} target="_blank"><img className="guild_picture" src={bot.icon && bot.icon != "0" ? `https://cdn.discordapp.com/avatars/${bot.id}/${bot.icon}` : `https://dummyimage.com/400x400/000/ffffff&text=${bot.name}`} alt={bot.name} title={bot.name} /></a>
                                                                    <h2>{bot?.name}</h2>
                                                                    <a href={`https://discord.com/api/oauth2/authorize?client_id=${bot?.id}&permissions=8&scope=bot`} target="_blank"><button className="display_guild_list_button">Invite bot</button></a>
                                                                    <a onClick={() => { botDaily(bot?.id); }}><button className="display_guild_list_button">Claim Daily</button></a>
                                                                    <a onClick={() => { editToken(bot?.id) }}><button className="display_guild_list_button">Edit Token</button></a>
                                                                    <a onClick={() => { switchMode(bot?.id) }}><button className="display_guild_list_button">Mode: {bot.public === 1 ? "Public" : "Private"}</button></a>
                                                                    <a onClick={() => { deleteBot_(bot?.id) }}><button className="display_guild_list_button red">Delete Bot</button></a>
                                                                </div>
                                                            </>
                                                        )
                                                    }) : <h1>You don't have any bots to display here.</h1>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default Manage_Bot;