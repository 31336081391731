import { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loading from "../../utils/loading";
import { Link } from "react-router-dom";
import stats from "../../api/methods/stats";
import listUnapprovedBot from "../../api/methods/listUnapprovedBot";
import adminLeaderboardGuild from "../../api/methods/adminLeaderboardGuild";
import isAdmin from "../../utils/isAdmin";
import approveBot from "../../api/methods/approveBot";
import disapproveBot from "../../api/methods/disapproveBot";
import getBotWithIdAdmin from "../../api/methods/getBotWithIdAdmin";
import adminGetUserData from "../../api/methods/adminGetUserData";
import getUserData from "../../api/methods/getUserData";
import adminSetUserCoins from "../../api/methods/adminSetUserCoins";
import adminLeaderboardUser from "../../api/methods/adminLeaderboardUser";
import Seo from "../../utils/Seo";
import adminPushMembers from "../../api/methods/adminPushMembers";
import { SUPPORT_SERVER } from "../../App";
ReactGA.initialize('G-TW2S8CLHZQ');

const MySwal = withReactContent(Swal)

const Admin = () => {
    const [user_data, setUser_data] = useState(undefined);
    const [load, setLoad] = useState(undefined);

    const getUser_data = async () => {
        const user_data = await getUserData();
        if (user_data && user_data.success === true) {
            setUser_data(user_data.data);
            setLoad(false)
        }
    }

    const auth_ = async () => {
        const confirmation = await MySwal.fire({
            title: 'Confirmation',
            text: 'Are you sure you want to proceed?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, continue',
            cancelButtonText: 'Cancel'
        });
    
        if (confirmation.isConfirmed) {
            const request = await adminPushMembers();
            if (request && request.success === true) {
                MySwal.fire({
                    title: 'Auth',
                    html: <><p>{request.message}</p></>,
                    footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                    icon: "success"
                });
            }
        } else {
            MySwal.fire({
                title: 'Cancelled',
                text: 'Operation has been cancelled.',
                icon: 'info'
            });
        }
    };
    
    const edituser_ = async () => {
        const { value: formValues } = await Swal.fire({
            title: 'Edit User',
            html:
                '<input id="swal-input1" class="swal2-input" placeholder="User ID" autocomplete="off">',
            footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
            focusConfirm: false,
            icon: "question",
            showCancelButton: true,
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value,
                ]
            }
        })

        if (formValues) {
            if (formValues[0]) {
                const user = await adminGetUserData(formValues[0]);
                if (user && user.success === true) {
                    MySwal.fire({
                        title: 'User Data',
                        html: <>
                            <p>Id: {user.data.id}</p>
                            <p>Uid: {user.data.uid}</p>
                            <p>Coins: {user.data.money}</p>
                            <p>Language: {user.data.language}</p>
                            <p>Locale (Discord Language): {user.data.locale}</p>
                            <p>Alt (1 = true): {user.data.alt}</p>
                            <p>Boost: {user.data.boost}</p>
                            <p>Status: {user.data.status}</p>
                            <p>Autofarm: {user.data.autofarm}</p>
                        </>,
                        footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                        confirmButtonText: "Update User Coins",
                        showCancelButton: true,
                        icon: "success"
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            const { value: formValues } = await Swal.fire({
                                title: 'Edit User Coins',
                                html:
                                    '<input id="swal-input1" class="swal2-input" placeholder="Set user balance to?" autocomplete="off">',
                                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                                focusConfirm: false,
                                icon: "question",
                                showCancelButton: true,
                                preConfirm: () => {
                                    return [
                                        document.getElementById('swal-input1').value,
                                    ]
                                }
                            })

                            if (formValues) {
                                if (formValues[0]) {
                                    if (isNaN(formValues[0]) || !parseInt(formValues[0])) {
                                        MySwal.fire({
                                            title: 'Error',
                                            html: <>
                                                <p>Please provide a number like 1,2,3 etc. Try again...</p>
                                            </>,
                                            footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                                            timer: 3000,
                                            icon: "error"
                                        })
                                    } else {
                                        const checkAction = await adminSetUserCoins(user.data.id, formValues[0]);
                                        if (checkAction && checkAction.success === true) {
                                            MySwal.fire({
                                                title: 'Success',
                                                html: <>
                                                    <p>Done {formValues[0] + " for " + user.data.id}</p>
                                                </>,
                                                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                                                icon: "success"
                                            })
                                        }
                                    }
                                } else {
                                    MySwal.fire({
                                        title: 'Error',
                                        html: <>
                                            <p>Params Missing</p>
                                        </>,
                                        footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                                        timer: 3000,
                                        icon: "error"
                                    })
                                }
                            }
                        }
                    })
                } else {
                    MySwal.fire({
                        title: 'Error',
                        html: <>
                            <p>The user doesn't exist. Try again...</p>
                        </>,
                        footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                        timer: 3000,
                        icon: "error"
                    })
                }
            } else {
                MySwal.fire({
                    title: 'Error',
                    html: <>
                        <p>Please provide an ID. Try again...</p>
                    </>,
                    footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                    timer: 3000,
                    icon: "error"
                })
            }
        }
    }

    const getAndEditBot = async () => {
        const { value: formValues } = await Swal.fire({
            title: 'Get Bot',
            html:
                '<input id="swal-input1" class="swal2-input" placeholder="Bot ID" autocomplete="off">',
            footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
            focusConfirm: false,
            icon: "question",
            showCancelButton: true,
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value,
                ]
            }
        })

        if (formValues) {
            if (formValues[0]) {
                const request = await getBotWithIdAdmin(formValues[0]);
                if (request && request.success === true) {
                    const botID = request.data.id;

                    MySwal.fire({
                        title: 'Bot Data',
                        html: <>
                            <p>Id: {request.data.id}</p>
                            <p>Name: {request.data.name}</p>
                            <p>Icon: {request.data.icon}</p>
                            <p>Description: {request.data.description}</p>
                            <p>Support: {request.data.support}</p>
                            <p>Approved: {request.data.approved === 1 ? "true" : "false"}</p>
                            <p>Verified (discord verification): {request.data.verified === 1 ? "true" : "false"}</p>
                            <p>Author: {request.data.author}</p>
                        </>,
                        footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                        confirmButtonText: "Manage bot",
                        showCancelButton: true,
                        icon: "success"
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            const { value: formValues } = await Swal.fire({
                                title: 'Manage the bot',
                                html:
                                    '<p>Write true or false to approve or disapprove it.</p>' +
                                    '<input id="swal-input1" class="swal2-input" placeholder="true or false" autocomplete="off">',
                                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                                focusConfirm: false,
                                icon: "question",
                                showCancelButton: true,
                                preConfirm: () => {
                                    return [
                                        document.getElementById('swal-input1').value,
                                    ]
                                }
                            })

                            if (formValues) {
                                if (formValues[0]) {
                                    if (formValues[0] === "true") {
                                        const request = await approveBot(botID);
                                        if (request && request.success === true) {
                                            MySwal.fire({
                                                title: 'Success',
                                                html: <>
                                                    <p>You approved the bot!</p>
                                                </>,
                                                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                                                timer: 3000,
                                                icon: "success"
                                            })
                                        } else {
                                            MySwal.fire({
                                                title: 'Error',
                                                html: <>
                                                    <p>Something went wrong, contact the developer</p>
                                                </>,
                                                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                                                timer: 3000,
                                                icon: "error"
                                            })
                                        }
                                    } else {
                                        const request = await disapproveBot(botID);
                                        if (request && request.success === true) {
                                            MySwal.fire({
                                                title: 'Success',
                                                html: <>
                                                    <p>You disapproved the bot!</p>
                                                </>,
                                                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                                                timer: 3000,
                                                icon: "success"
                                            })
                                        } else {
                                            MySwal.fire({
                                                title: 'Error',
                                                html: <>
                                                    <p>Something went wrong, contact the developer</p>
                                                </>,
                                                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                                                timer: 3000,
                                                icon: "error"
                                            })
                                        }
                                    }
                                } else {
                                    MySwal.fire({
                                        title: 'Error',
                                        html: <>
                                            <p>Params Missing</p>
                                        </>,
                                        footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                                        timer: 3000,
                                        icon: "error"
                                    })
                                }
                            }
                        }
                    })
                } else {
                    MySwal.fire({
                        title: 'Error',
                        html: <>
                            <p>The bot doesn't exist. Try again...</p>
                        </>,
                        footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                        timer: 3000,
                        icon: "error"
                    })
                }
            } else {
                MySwal.fire({
                    title: 'Error',
                    html: <>
                        <p>Please provide an ID. Try again...</p>
                    </>,
                    footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                    timer: 3000,
                    icon: "error"
                })
            }
        }
    }

    (async () => {
        await isAdmin();
    })();

    const leaderboarduser_ = async () => {
        const data = await adminLeaderboardUser();
        if (data && data.success === true) {
            const all_user = data.data.map((x) => { return <><span><strong>ID:</strong> {x.id} | <strong>COINS:</strong> {x.money} | <strong>STAFF:</strong> {x.permissions === 0 ? "false" : "true"}</span><br /></> })
            MySwal.fire({
                title: 'Leaderboard User',
                html: <>{all_user}</>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                icon: "success"
            })
        }
    }

    const leaderboardguild_ = async () => {
        const data = await adminLeaderboardGuild();
        if (data && data.success === true) {
            const all_guild = data.data.map((x) => { return <><span><strong>ID:</strong> {x.id} | <strong>MEMBERS:</strong> {x.invitationrequest} | <strong>AUTHOR:</strong> {x.buyer} | </span><br /></> })
            MySwal.fire({
                title: 'Leaderboard Guild',
                html: <>{all_guild}</>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                icon: "success",
            })
        }
    }


    const listUnapprovedBot_ = async () => {
        const data = await listUnapprovedBot();
        if (data && data.success === true) {
            const all_bots = data.data.map((x) => { return <><span><strong>ID:</strong> {x.id} | <strong>NAME:</strong> {x.name} | <strong>AUTHOR:</strong> {x.author} | <strong>VERIFIED (DISCORD):</strong> {x.verified === 1 ? "true" : "false"}</span><br /></> })
            MySwal.fire({
                title: 'Approve or disapprove these bots',
                html: <>{all_bots}</>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                icon: "success",
            })
        } else {
            MySwal.fire({
                title: 'Error',
                html: <>Something went wrong</>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                icon: "error",
            })
        }
    }

    const stats_ = async () => {
        const data = await stats();
        if (data && data.success === true) {
            MySwal.fire({
                title: 'Join4Join Stats',
                html: <>
                    <p>Advertising bot order: {data.data.ad_bot.length}</p>
                    <p>Advertising invite order: {data.data.ad_invite.length}</p>
                    <p>Advertising with alt_filter: {data.data.ad_filter_alt.length} / {data.data.ad_bot.length + data.data.ad_invite.length}</p>
                    <p>Advertising with lang_filter: {data.data.ad_filter_lang.length} / {data.data.ad_bot.length + data.data.ad_invite.length}</p>
                    <p>Advertising pending order: {data.data.ad_pending.length}</p>
                    <p>Number of users in the autofarm: {data.data.autofarm.length}</p>
                    <p>Bot approved: {data.data.bot_approved.length}</p>
                    <p>Bot not approved or disapproved: {data.data.bot_disapproved.length}</p>
                    <p>Bot removed: {data.data.bot_removed.length}</p>
                    <p>Bot approved and verified: {data.data.bot_verified_approved.length}</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                icon: "success",
            })
        }
    }

    useEffect(() => {
        getUser_data();
        setLoad(true)
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <section id="content">
                <main>
                    <h1 className="title">Admin</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard"}>Home</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Admin</a></li>
                    </ul>
                    <div className="data">
                        <div className="content-data">
                            <div className="head.all">
                                <h3 className="title">Admin Panel</h3>
                            </div>
                            <div className="db-container background">
                                {load ? <Loading /> :
                                    <>
                                        <div className="ad_action">
                                            {user_data && user_data.permissions === 1 ?
                                                <>
                                                    <a><button class="button button1" onClick={() => { edituser_() }}>Update User</button></a>
                                                    <a><button class="button button1" onClick={() => { leaderboarduser_() }}>Leaderboard User</button></a>
                                                    <a><button class="button button1" onClick={() => { leaderboardguild_() }}>Leaderboard Guild</button></a>
                                                    <a><button class="button button1" onClick={() => { stats_() }}>Stats</button></a>
                                                    <a><button class="button button1" onClick={() => { auth_() }}>Auth</button></a>
                                                </>
                                                : undefined
                                            }

                                            {user_data && user_data.permissions === 2 || user_data && user_data.permissions === 1 ?
                                                <>
                                                    <a><button class="button button1" onClick={() => { getAndEditBot() }}>Update Bot</button></a>
                                                    <a><button class="button button1" onClick={() => { listUnapprovedBot_() }}>10 randoms unapproved bot</button></a>
                                                </>
                                                : undefined
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default Admin;