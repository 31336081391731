import { Link, useLocation } from "react-router-dom";
import Logo from "../../../public/img/logoj4j.png";
import callback from "../../../utils/callback";
import login from "../../../api/methods/login";
import { useEffect } from "react";

const menuToggle = () => {
    document.querySelector('.burger').classList.toggle('menu-opened');
    var buger = document.querySelector('.burger');
    document.querySelector('nav').classList.toggle("opened")
    if (buger && buger.classList.contains("menu-opened") === true) {
        document.querySelector("html").classList.add('overflow');
    } else {
        document.querySelector("html").classList.remove('overflow');
    }
}

const login_user = async (code, state) => {
    const res = await login(code, window.localStorage.getItem("affiliation"), state);
    if (res && res.success === true) {
        if (res.home) return window.location.replace("/");
        window.localStorage.setItem("token", res.token);
        if (window.localStorage && window.localStorage?.getItem("callback") && window.localStorage?.getItem("callback") === "/dashboard") {
            window.location.replace("/dashboard");
        } else {
            if (window.localStorage && window.localStorage?.getItem("callback")) {
                window.location.replace("/callback");
            } else {
                window.location.replace("/dashboard");
            }
        }
    } else {
        window.location.replace("/login/discord");
    }
}

if (window.location.pathname === "/login") {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('code')) {
        login_user(searchParams.get('code'), searchParams.get('state'));
    }
}

if (window.location.pathname === "/") {
    const affiliation = window.location.search && window.location.search.split("=").length === 2 ? window.location.search.split("=")[1] : null;
    window.localStorage.setItem("affiliation", affiliation);
}

if (window.location.pathname === "/callback") {
    callback();
}

const cookie = () => {
    const cookieContainer = document.querySelector(".cookie-container");
    if (cookieContainer && cookieContainer.classList) {
        cookieContainer.classList.remove("active");
        localStorage.setItem("cookieBannerDisplayed", "true");
    }
}

const isLogin = window.localStorage.getItem("token") ? true : false

const Nav = () => {
    const location = useLocation();

    useEffect(() => {
        if (!localStorage.getItem("cookieBannerDisplayed") && location.pathname !== "/") {
            setTimeout(() => {
                const cookieContainer = document.querySelector(".cookie-container");
                if (cookieContainer && cookieContainer.classList) {
                    cookieContainer.classList.add("active");
                }
            }, 2000);
        }
    }, [location.pathname]);


    return (
        <>
            <nav className="lp_navbar" id="navbar">
                <div className="navContainer">
                    <a className="logo" href="/">
                        <img src={Logo} alt="join4join website logo" height={50} width={50} />
                        JOIN4JOIN
                    </a>
                    <ul>
                        <li className="nav_responsive">
                            <Link to={"/"}>Home</Link>
                        </li >
                        <li className="nav_responsive">
                            <Link to={"/about"}>About Us</Link>
                        </li>
                        {/* <li className="nav_responsive">
                            <Link to={"/features"}>Features</Link>
                        </li> */}
                        <li className="nav_responsive">
                            <Link to={isLogin ? "/dashboard" : "/login"} onClick={() => { 
                                window.localStorage.setItem("callback", "/dashboard") }}>
                                <button>Get Started</button>
                            </Link>
                        </li>
                        <li>
                            <div className="burger">
                                <button className="burger-menu-icon" id="button-menu-mobile" onClick={() => { menuToggle() }} aria-label="responsive button to display menu">
                                    <svg width="34" height="34" viewBox="0 0 100 100">
                                        <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path>
                                        <path className="line line2" d="M 20,50 H 80"></path>
                                        <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path>
                                    </svg>
                                </button>
                            </div>
                        </li>
                    </ul>
                    <div id="navbar-menu" className="navbar-menu">
                        <ul class="navbar-links">
                            <li class="navbar-item"><Link to="/" className="navbar-link">Home</Link></li>
                            <li class="navbar-item" ><Link to="/about" className="navbar-link">About Us</Link></li>
                            <li class="navbar-item" ><Link to="/features" className="navbar-link">Features</Link></li>
                            <li class="navbar-item" ><Link to="/dashboard" className="navbar-link blue">Get Started</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div class="cookie-container">
                <p>We use cookies to give you the best experience on our site. Read our <Link to="/privacy">privacy policy</Link> and our <Link to="/terms">terms of use</Link> for further informations.</p>
                <button onClick={cookie} class="cookie-btn">Okay</button>
            </div>
        </>
    );
};

export default Nav;