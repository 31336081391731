import Seo from "../../utils/Seo";
import Footer from "./layout/footer";
import Modal_Login from "../../public/img/modal_login.svg";

const Login = () => {
    const isLogin = window.localStorage.getItem("token") ? true : false

    return (
        <>
            <Seo dynamic={true}
                title={"Login"}
                description={"Login to Join4Join using your discord account and experience rapid server growth now."}
            />
            <div className="lp-container">
                <div className="position">
                    <div className="middle">
                        <div className="padding reverse">
                            <div className="left">
                                <div className="left_position">
                                    <img src={Modal_Login} />
                                </div>
                            </div>
                            <div className="right">
                                <div className="right_position">
                                    <h1>Login</h1>
                                    <p>Login to Join4Join using your discord account and experience rapid server growth now!</p>
                                    <a href={"/login/discord"} onClick={() => {
                                        window.localStorage.setItem("callback", "/dashboard")
                                    }}>
                                        <button>Login</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Login;