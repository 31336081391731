import { useEffect } from "react";
import coinsEffect from "../../../utils/coinsEffect";
import farmServer from "../../../api/methods/farmServer";
import getUserData from "../../../api/methods/getUserData";
import getUserFarmAll from "../../../api/methods/getUserFarmAll";
import { useState } from "react";
import Loading from "../../../utils/loading";
import { Link } from "react-router-dom";
import getAutoFarmAmount from "../../../api/methods/getAutoFarmAmount";
import stopAutoFarm from "../../../api/methods/stopAutoFarm";
import runAutoFarm from "../../../api/methods/runAutoFarm";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { SUPPORT_SERVER } from "../../../App";
import refreshRewards from "../../../api/methods/refreshRewards";

const MySwal = withReactContent(Swal)

const Farm_Page = () => {
    const [farm_server, setFarm_server] = useState([]);
    const [farm_server_invite, setFarm_server_invite] = useState([]);
    const [autofarm, setAutofarm] = useState("OFF");
    const [load, setLoad] = useState(undefined);
    const [available, setAvailable] = useState(undefined);
    const [baseAvailable, setBaseAvailable] = useState(undefined);
    const [status, setStatus] = useState("offline");
    const [alt, setAlt] = useState("no");

    const editJoinMode = async (element) => {
        if (element && element.target && element.target.value) {
            if (element.target.value === "bot") {
                var add_bot = document.getElementById("join_bot");
                var add_invite = document.getElementById("join_invite");
                var invite_message = document.getElementById("join_invite_message");
                add_bot.style.display = "block"
                add_invite.style.display = "none"
                invite_message.style.display = "none"
            } else if (element.target.value === "invite") {
                var add_bot = document.getElementById("join_bot");
                var add_invite = document.getElementById("join_invite");
                var invite_message = document.getElementById("join_invite_message");
                add_bot.style.display = "none"
                add_invite.style.display = "block"
                invite_message.style.display = "block"
            }
        }
    }


    const getFarmServers = async (token) => {
        if (token) {
            const servers = await getUserFarmAll(token);
            if (servers && servers.success === true) {
                if (servers.data[0]) { setFarm_server(servers.data[0]) }
                if (servers.data[1]) { setFarm_server_invite(servers.data[1]) }
                setLoad(undefined);
            } else {
                if (servers && servers.success === false && servers.action && servers.action === "logout") {
                    window.localStorage.removeItem("token")
                    window.location.replace("/");
                }
            }
        }
    }

    const getAutoFarmAmount_ = async () => {
        const request = await getAutoFarmAmount();
        if (request && request.success === true) {
            setAvailable(request.amount)
            setBaseAvailable(request.amount)
        } else {
            setAvailable(100)
        }
    }

    const stopAutoFarm_ = async () => {
        const request = await stopAutoFarm()
        if (request.success === true) {
            await getUser_data();
            await getAutoFarmAmount_();
            setStatus("offline")
        }
    }

    const runAutoFarm_ = async () => {
        const element = Number(document.getElementById("autofarm")?.value) ?? Number(available);
        if (!element || isNaN(element)) {
            return MySwal.fire({
                title: 'System',
                html: <>
                    <p>Please provide a number.</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "error"
            })
        }

        const request = await runAutoFarm(element);
        if (request && request.success === true) {
            getUser_data();
            getAutoFarmAmount_();
            setStatus("online")
        } else {
            MySwal.fire({
                title: 'System',
                html: <>
                    <p>Something went wrong. {request?.message}</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "error"
            })
        }
    }

    const getUser_data = async () => {
        const user_data = await getUserData();
        if (user_data && user_data.success === true) {
            // console.log(user_data.data.alt)
            // setAlt(user_data.data.alt === 0 ? "no" : "yes");
            setAutofarm(user_data.data.autofarm);
            if (user_data.data.autofarm != "OFF") {
                setStatus("online")
            }
        }
    }

    const joinServer = async (id) => {
        setLoad(true);
        window.grecaptcha.ready(async () => {
            window.grecaptcha
                .execute("6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E")
                .then(async (token) => {
                    const checkFarmSuccess = await farmServer(id, token);
                    if (checkFarmSuccess && checkFarmSuccess.success === true) {
                        handleLoaded();
                        coinsEffect();
                    } else {
                        window.localStorage.removeItem("token")
                        window.location.replace("/");
                    }
                })
        })
    }

    const joinServerWithInvite = async (guild) => {
        window.open(guild.invite, "_blank");
        setFarm_server_invite((prev) => prev.filter((server) => server.id !== guild.id));

        setTimeout(async () => {
            const request = await refreshRewards();
            if (request && request.success === true) {
                handleLoaded();
                coinsEffect();
            }
        }, 17000);
    }

    const handleLoaded = () => {
        window.grecaptcha.ready(async () => {
            window.grecaptcha
                .execute("6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E")
                .then(async (token) => {
                    getFarmServers(token);
                })
        })
    }

    useEffect(() => {
        setLoad(true);
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E"
        script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
        getUser_data();
        getAutoFarmAmount_();
    }, []);

    useEffect(() => {
        if (status === "online") {
            const intervalId = setInterval(async () => {
                const user_data = await getUserData();
                if (user_data && user_data.success === true) {
                    setAutofarm(user_data.data.autofarm);
                    coinsEffect()
                }
            }, 3000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [status]);

    return (
        <>
            <main>
                <h1 className="title">Farm</h1>
                <ul className="breadcrumbs">
                    <li><Link to={"/dashboard/join4join"}>Join4Join</Link></li>
                    <li className="divider">/</li>
                    <li><a href="#" className="active">Farm</a></li>
                </ul>
                <div className="data">
                    <div className="content-data">
                        <div className="head.all">
                            <h3 className="title">Farm Page</h3>
                        </div>
                        <div className="db-container background">
                            {load ? <Loading /> :
                                <>
                                    <label className="a_blue">Choose a method to farm servers</label>
                                    <select onChange={editJoinMode.bind(this)} onInput={editJoinMode}>
                                        <option value="bot">Join with the bot</option>
                                        <option value="invite">Join with discord invite</option>
                                    </select>
                                    <div class="info-msg">
                                        <div className="position_info_msg">
                                            <div>
                                                <div><i class="fa fa-info-circle"></i></div><div>Earn 0.75 coins per farmed server.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="error-msg">
                                        <div className="position_info_msg">
                                            <div>
                                                <div><i class="fa fa-warning"></i></div><div>Don't leave the server within 4 days, or you'll lose 2 coins. Check <Link style={{ color: "#5151ff" }} to={"/dashboard/join4join/check"}>here</Link> for servers you can leave anytime.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="join_bot">
                                        <div className="display_farm">
                                            {farm_server.length > 0 ? farm_server.map((guild) => {
                                                return (
                                                    <>
                                                        <img onClick={() => { joinServer(guild.id) }} src={guild.picture && guild.picture != "0" && guild.picture != "null" ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.picture}` : `https://dummyimage.com/400x400/000/ffffff&text=${guild.name}`} alt={guild.name} title={guild.name} />
                                                    </>
                                                )
                                            }) : <p className="a_red">You don't have any servers available to join at the moment.</p>}
                                        </div>
                                    </div>
                                    <div id="join_invite" style={{ display: "none" }}>
                                        <div className="display_farm">
                                            {farm_server_invite.length > 0 ? farm_server_invite.map((guild) => {
                                                return (
                                                    <>
                                                        <img onClick={() => { joinServerWithInvite(guild); }} src={guild.picture && guild.picture != "0" && guild.picture != "null" ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.picture}` : `https://dummyimage.com/400x400/000/ffffff&text=${guild.name}`} alt={guild.name} title={guild.name} />
                                                    </>
                                                )
                                            }) : <p className="a_red">You don't have any servers available to join at the moment.</p>}
                                        </div>
                                    </div>
                                    <div id="join_invite_message" class="info-msg" style={{ display: "none" }}>
                                        <div className="position_info_msg">
                                            <div>
                                                <div><i class="fa fa-info-circle"></i></div><div>Coins will be added within a minute. Please wait and avoid refreshing. If not, click <Link to="/claim" target="_blank" style={{ color: "#5151ff" }}>here</Link> to claim.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="autofarm">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="modal-box">
                                                        <div className="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-body">
                                                                        <div className="icon"><i className="fa fa-rocket"></i></div>
                                                                        <h3 className="title"><i className="fa fa-check"></i>Authorize Auto-Farm</h3>
                                                                        <p className="description">Unlock rapid server growth with our Discord auto-farm mode! Join multiple servers at once, customize settings, and watch your coins soar. 🚀</p>
                                                                        {/* <p className="description">Only accounts which are not detected as alt accounts can use this feature. ⚠️</p> */}
                                                                        {
                                                                            // alt && alt === "no" ?
                                                                            autofarm && autofarm === "OFF" ?
                                                                                <>
                                                                                    <p className="title_input">Amount of server you can join:</p>
                                                                                    <input type="number" className="input" id="autofarm" onChange={event => setAvailable(event.target.value)} value={available} min={5} max={baseAvailable} />
                                                                                    <button className="subscribe" onClick={() => { runAutoFarm_() }}>Start</button>
                                                                                    <button className="subscribe deny" style={{ backgroundColor: "#7f2a20" }} disabled>Stop</button>
                                                                                </> : <>
                                                                                    <p className="title_input">Auto Farm progress:</p>
                                                                                    <input type="number" className="input" id="autofarm" value={autofarm ? autofarm : 0} readOnly />
                                                                                    <button className="subscribe" style={{ backgroundColor: "#017a5c" }} disabled>Start</button>
                                                                                    <button className="subscribe deny" onClick={() => { stopAutoFarm_() }}>Stop</button>
                                                                                </>
                                                                            // : <>
                                                                            //     <p className="description">You have been identified as an alt account. ⛔</p>
                                                                            // </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Farm_Page;