import getUserServers from "../../../api/methods/getUserServers";
import Loading from "../../../utils/loading";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Manage_Page = () => {
    const [servers, setServers] = useState([]);
    const [load, setLoad] = useState(undefined);

    const getUserServers_ = async () => {
        const request = await getUserServers();
        if (request && request.success === true) {
            setServers(request.data);
            setLoad(undefined)
        } else {
            window.localStorage.removeItem("token");
            window.location.replace("/");
            console.log(request);
        }
    }

    const editServersWithAdmin = async (element) => {
        if (element && element.target && element.target.value) {
            if (element.target.value === "with_admin") {
                var with_admin = document.getElementById("with_admin");
                var without_admin = document.getElementById("without_admin");
                with_admin.style.display = "flex"
                without_admin.style.display = "none"
            } else if (element.target.value === "without_admin") {
                var with_admin = document.getElementById("with_admin");
                var without_admin = document.getElementById("without_admin");
                with_admin.style.display = "none"
                without_admin.style.display = "flex"
            }
        }
    }

    useEffect(() => {
        setLoad(true)
        getUserServers_()
    }, []);

    return (
        <>
            <main>
                <h1 className="title">Manage</h1>
                <ul className="breadcrumbs">
                    <li><Link to={"/dashboard/join4join"}>Join4Join</Link></li>
                    <li className="divider">/</li>
                    <li><a href="#" className="active">Manage</a></li>
                </ul>
                <div className="data">
                    <div className="content-data">
                        <div className="head.all">
                            <h3 className="title">Select a server</h3>
                            <div className="db-container background">
                                {load ? <Loading /> :
                                    <>
                                        <select onChange={editServersWithAdmin.bind(this)} onInput={editServersWithAdmin}>
                                            <option value="with_admin">Your servers</option>
                                            <option value="without_admin">Other servers</option>
                                        </select>
                                        <div id="with_admin" style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                                            {
                                                servers.length > 0 ? servers.filter(u => (u.permissions & 2146958591) === 2146958591).map((guild) => {
                                                    return (
                                                        <>
                                                            <div className="display_guild_list">
                                                                <a href={`/dashboard/join4join/manage/` + guild.id}><img className="guild_picture" src={guild.icon ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}` : `https://dummyimage.com/400x400/000/ffffff&text=${guild.name}`} alt={guild.name} title={guild.name} /></a>
                                                                <a href={`/dashboard/join4join/manage/` + guild.id}><button className="display_guild_list_button">Manage Ad</button></a>
                                                            </div>
                                                        </>
                                                    )
                                                }) : <h1>You don't have servers to display here.</h1>
                                            }
                                        </div>

                                        <div id="without_admin" style={{ display: "none", justifyContent: "center", flexWrap: "wrap" }}>
                                            {
                                                servers.length > 0 ? servers.filter(u => (u.permissions & 2146958591) !== 2146958591).map((guild) => {
                                                    return (
                                                        <>
                                                            <div className="display_guild_list">
                                                                <a href={`/dashboard/join4join/manage/` + guild.id}><img className="guild_picture" src={guild.icon ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}` : `https://dummyimage.com/400x400/000/ffffff&text=${guild.name}`} alt={guild.name} title={guild.name} /></a>
                                                                <a href={`/dashboard/join4join/manage/` + guild.id}><button className="display_guild_list_button">Manage Ad</button></a>
                                                            </div>
                                                        </>
                                                    )
                                                }) : <h1>You don't have servers to display here.</h1>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Manage_Page;